import * as React from 'react';
import Link from 'gatsby-link';
import App from 'src/components/App';
import CustomHelmet from 'src/components/CustomHelmet';
import { P, H1 } from 'src/components/misc/MDXComponents';

interface Props {
  location: any;
  history: any;
}

const NotFound: React.FC<Props> = ({ location, history }) => {
  return (
    <App history={history}>
      <style jsx={true}>{`
        .wrapper {
          @p: .w100, .h100, .mv20, .mhAuto, .flex, .flexColumn, .itemsCenter, .justifyCenter;
          min-height: 60vh;
        }
        .btn {
          @p: .mhAuto, .white;
        }
        .text {
          @p: .white;
        }
      `}</style>
      <CustomHelmet title={'Not found'} description={'Page not found'} overrideDescription={'Page not found'} />
      <div className="wrapper">
        <H1 className="title">404</H1>
        <H1 className="title">Page not found</H1>
        <div className="btn">
          <Link to="/">
            <span className="text">Back to Home</span>
          </Link>
        </div>
      </div>
    </App>
  );
};

export default NotFound;
